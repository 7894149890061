@import '../../theme/variables';

@keyframes slide {
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
}

.slider:nth-child(1) {
  animation-delay: 0s;
}

.slider:nth-child(2) {
  animation-delay: 10s;
}

.slider:nth-child(3) {
  animation-delay: 20s;
}

.slider:nth-child(4) {
  animation-delay: 30s;
}

.slider {
  width: 100%;
  height: 61rem;
  animation: slide 40s infinite;
  opacity: 0;
  flex-shrink: 0;
  object-fit: cover;
  position: absolute;
  left: auto;
  right: auto;
}

.container {
  position: relative;
  width: 100vw;
  height: 61rem;
  overflow: hidden;
}

.camera {
  position: relative;
  width: 100vw;
  height: 60.9rem;
  opacity: 1;
  transition: ease 0.4s all;
  color: $color-white;

  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(49, 71, 112, 0.86);
    padding: 3rem 4rem;
  }

  &__title {
    font-weight: $font-bold;
    font-size: $font-size-xl;
    line-height: 3.6rem;
    letter-spacing: 0.075rem;
  }

  &__box {
    display: flex;
    align-items: center;
  }

  &__dot {
    width: 3.5rem;
    height: 3.5rem;
    background-color: $color-green;
    border-radius: 50%;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  &__text {
    font-weight: $font-regular;
    font-size: 3.4rem;
    line-height: 4.1rem;
    letter-spacing: 0.075rem;
  }

  &__icon {
    width: 4.1rem;
    height: 4.1rem;
    margin-right: 2rem;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}
